
import React, { Component } from "react";
import { FormGroup, Form } from "react-bootstrap";
import Modal from 'react-modal';
import { deleteToken, getUserId, setJWT } from '../services/TokenService';
import UserService from '../services/UserService';
import CustomInput from '../components/CustomInput/CustomInput'
import CustomLabel from '../components/CustomLabel/CustomLabel'
import NotificationSystem from 'react-notification-system';
import { Button, FormControl } from "react-bootstrap";
import { withRouter } from "react-router-dom"
import i18n from "../i18configuration/i18";

class UserProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {},
            modal: {
                show: false
            },
            deletionModal: {
                show: false
            },
            agency: false
        }
    }

    userService = new UserService();
    componentDidUpdate() {
        if (this.state.agency) {
            var a = this.state.user.isAgency;

            this.setState({
                agency: false,
                user: {
                    ...this.state.user,
                    isAgency: this.state.newState
                }
            })
        }

    }
    componentDidMount() {
        var userId = getUserId()
        this.userService.getUserById(userId).then(res => {
            setJWT(res.data.token)

            this.setState({
                user: res.data.data
            })
        }).catch(err => {
            console.log("error");
            console.log(err.response)
        })
    }
    getClass = (input) => {
        var className = "search-input-checkbox-div flex font-20 w-30 ";
        if (input.formGroup != undefined && input.formGroup != null) className += input.formGroup;
        return className;
    }

    isLabelNeeded = (text) => {
        if (text == "" || text == "icon") return false;
        return true;
    }
    handleInputChange = (el) => {
        var tempData = {};

        tempData = {
            ...this.state.user,
            [el.target.name]: el.target.value
        }
        this.setState({
            user: {
                ...this.state.user,
                [el.target.name]: el.target.value
            }
        })

    }

    handleClick = (e, action) => {
        if (action === 'delete_account') {
            this.deleteAccount(e)
        }
        else if (action === 'change_password') {
            this.sendEmail(e);
        }
        else if (action === 'update') {

        }
    }

    login = () => {
        this.props.history.push('/user/login')
    }

    getInputs = () => {
        if (this.state.user == {}) return this.login();

        var inputGroup = [
            {
                label: {
                    text: i18n.t("Emri*"),//if empty the label wont show (it is defined in custom form)
                    class: ""
                },
                inputClass: "m-0-important  input-text",
                type: "text",
                placeholder: "",
                value: this.state.user.name || "",
                text: "",
                required: true,
                disabled: false,
                inputName: "name",
                formGroup: "flex m-0  flex-basis-35-perc vertical-flex flex-center",
                inputDiv: "h-50"
            }, {
                label: {
                    text: i18n.t("Mbiemri*"),//if empty the label wont show (it is defined in custom form)
                    class: ""
                },
                inputClass: "m-0-important  input-text",
                type: "text",
                placeholder: "",
                value: this.state.user.lastName || "",
                text: "",
                required: true,
                disabled: false,
                inputName: "lastName",
                formGroup: "flex  m-0  flex-basis-35-perc vertical-flex flex-center ml-15-px",
                inputDiv: "h-50"
            }, , {
                label: {
                    text: i18n.t("Agjension"),//if empty the label wont show (it is defined in custom form)
                    class: ""
                },
                inputClass: "m-0-important  input-text",
                type: "checkbox",
                placeholder: "",
                text: "",
                checked: this.state.user.isAgency,
                disabled: false,
                inputName: "isAgency",
                formGroup: "flex  m-0  flex-basis-35-perc flex-center agencyFormGroup",
                inputDiv: "h-50 w-20"
            }, , {
                label: {
                    text: i18n.t("Emri agjensionit*"),//if empty the label wont show (it is defined in custom form)
                    class: ""
                },
                inputClass: "m-0-important  input-text",
                type: "text",
                placeholder: "",
                value: this.state.user.isAgency ? this.state.user.agencyName : "",
                text: "",
                disabled: this.state.user.isAgency ? false : true,
                inputName: "agencyName",
                formGroup: "flex  m-0  flex-basis-35-perc vertical-flex flex-center ml-15-px agencyNameFormGroup",
                inputDiv: "h-50"
            }, {
                label: {
                    text: i18n.t("Email*"),//if empty the label wont show (it is defined in custom form)
                    class: ""
                },
                inputClass: "m-0-important  input-text",
                type: "email",
                placeholder: "",
                value: this.state.user.email || "",
                text: "",
                required: true,
                disabled: false,
                inputName: "email",
                formGroup: "flex  m-0  flex-basis-30-perc vertical-flex flex-center",
                inputDiv: "h-50"
            }, {
                label: {
                    text: i18n.t("Numri telefonit*"),//if empty the label wont show (it is defined in custom form)
                    class: ""
                },
                inputClass: "m-0-important  input-text",
                type: "number",
                placeholder: "",
                required: true,
                value: this.state.user.telephone || "",
                text: "",
                disabled: false,
                inputName: "telephone",
                formGroup: "flex  m-0  flex-basis-24-perc vertical-flex flex-center ml-15-px phoneFormGroup",
                inputDiv: "h-50"
            }, {
                label: {
                    text: i18n.t("Qyteti"),//if empty the label wont show (it is defined in custom form)
                    class: ""
                },
                inputClass: "m-0-important  input-text",
                type: "text",
                placeholder: "",
                value: this.state.user.city || "",
                text: "",
                disabled: false,
                inputName: "city",
                formGroup: "flex  m-0  flex-basis-24-perc vertical-flex flex-center ml-15-px",
                inputDiv: "h-50"
            },
            {
                label: {
                    text: "",//if empty the label wont show (it is defined in custom form)
                    class: ""
                },
                inputClass: "m-0-important  bg-blue w-80",
                type: "submit",
                placeholder: "",
                value: i18n.t("Përditëso"),
                action: 'update',
                text: "",
                disabled: false,

                inputName: "input-submit",
                formGroup: "flex  m-0  w-30 vertical-flex flex-center",
                inputDiv: "h-50 flex flex-center"
            }, {
                label: {
                    text: "",//if empty the label wont show (it is defined in custom form)
                    class: ""
                },
                inputClass: "m-0-important  bg-blue white w-80 remove-btn-click-styles",
                type: "button",
                placeholder: "",
                value: i18n.t("Ndrysho fjalëkalimin"),
                text: "",
                disabled: false,
                resetPassword: true,
                action: 'change_password',
                inputName: "input-submit",
                formGroup: "flex  m-0  w-30 vertical-flex flex-center",
                inputDiv: "h-50 flex flex-center"
            }, , {
                label: {
                    text: "",//if empty the label wont show (it is defined in custom form)
                    class: ""
                },
                inputClass: "m-0-important  bg-red white w-80 remove-btn-click-styles-red ",
                type: "button",
                placeholder: "",
                value: i18n.t("Fshij llogarinë"),
                text: "",
                disabled: false,
                action: 'delete_account',
                deleteAccount: true,
                inputName: "input-submit",
                formGroup: "flex  m-0  w-30 vertical-flex flex-center",
                inputDiv: "h-50 flex flex-center"
            },
        ]
        return inputGroup;
    }
    notificationSystem = React.createRef();


    handleSubmit = (e) => {
        e.preventDefault();
        var notification = this.notificationSystem.current;
        if (this.checkEmpty()) {
            notification.addNotification({
                message: i18n.t('Ju lutem te mbushni te dhenat me yll(*)!'),
                level: 'warning',
                position: 'tc',
                autoDismiss: '5',
                dismissible: 'both',

            });
            return
        };
        this.setState({
            modal: {
                ...this.state.modal,
                show: true
            }
        })
    }

    handleClose = () => {
        this.setState({
            modal: {
                ...this.state.modal,
                show: false
            },
            deletionModal: {
                show: false
            }
        })
    }
    customStyles = {
        content: {
            top: '40vh',
            left: '30%',
            right: 'auto',
            bottom: 'auto',
            // marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '40vw',
            height: '20vh'
        }
    };

    checkEmpty = () => {
        var data = this.state.user;
        var err = "";
        var textReg = /^$/
        if (data.email == undefined || data.email.trim() == "") {
            err = "Ju lutem të mbushni fushën e emailit.";
        }
        else if (data.lastName == undefined || data.lastName.trim() == "") {
            err = "Ju lutem të mbushni fushën e mbiemrit.";
        }
        else if (data.name == undefined || data.name.trim() == "") {
            err = "Ju lutem të mbushni fushën e emrit.";
        }
        else if (data.agency) {
            if (data.agencyName == undefined || data.agencyName.trim() == "") {
                err = "Ju lutem të mbushni fushën e emrit të agjensionit.";
            }
        }
        else if (data.telephone == undefined || (data.telephone + " ").trim() == "" || data['telephone'] < 1) {
            err = "Ju lutem të mbushni fushën e telefonit.";
        }
        return false;
    }
    trimData = (verifyData) => {
        verifyData.name = verifyData.name.trim();
        verifyData.lastName = verifyData.lastName.trim();
        verifyData.email = verifyData.email.trim();
        verifyData.telephone = verifyData.telephone + " ";
        verifyData.telephone = verifyData.telephone.trim();
        if (verifyData.agencyName != undefined && verifyData.agencyName.trim() != "") {
            verifyData.agencyName = verifyData.agencyName.trim();
        }
        return verifyData
    }
    save = (e) => {
        e.preventDefault();
        var notification = this.notificationSystem.current;
        var user = this.state.user;
        user = this.trimData(user)
        this.userService.update(user.userID, user).then(res => {
            setJWT(res.data.token)
            notification.addNotification({
                message: i18n.t('Të dhënat janë përditësuar!'),
                level: 'success',
                position: 'tc',
                autoDismiss: '5',
                dismissible: 'both',

            });
            this.handleClose();
        }).catch(err => {
            notification.addNotification({
                message: i18n.t('Ndodhi një gabim, provoni përsëri ju lutem!'),
                level: 'warning',
                position: 'tc',
                autoDismiss: '5',
                dismissible: 'both',
            });
        })
        this.handleClose();
    }

    handlePhotoChange = (el) => {
        if (el.target.type == 'file') {
            this.setState({
                loading: true
            })
            this.onImageSelected(el);
        }
    }

    onImageSelected = (event) => {
        if (event.target.files.length == 0) return;
        var filesList = this.createArray(event.target.files);

        this.readFile(filesList[0]);


    }
    createArray = (files) => {
        return Object.values(files);
    }


    readFile = async (file) => {

        var reader = new FileReader();
        var fileConverted = ""
        var ext = file.name.split('.').pop();
        reader.readAsDataURL(file);
        reader.onloadend = async () => {
            fileConverted = reader.result;

            this.setState({
                loading: false,
                user: {
                    ...this.state.user,
                    profilePicBlobString: fileConverted,
                    fileExt: ext
                }
            })
        }

    }

    getImage = (user) => {
        var pic = "";
        if (user.profilePicBlobString != undefined && user.profilePicBlobString != null && user.profilePicBlobString != "") {
            pic = user.profilePicBlobString;
        }

        return pic;
    }

    removePhoto = () => {
        this.setState({
            user: {
                ...this.state.user,
                profilePicBlobString: "",
                fileExt: ""
            }
        })
    }
    agencyStateChanged = (val) => {

        this.setState({
            agency: true,
            newState: val
        })
    }
    sendEmail = (e) => {
        var notification = this.notificationSystem.current;
        e.preventDefault();

        var email = this.state.user.email;

        this.userService.sendEmailForPasswordReset(email).then(res => {
            notification.addNotification({
                message: i18n.t('Ju lutem te shikoni ne email per linkun per verifikim'),
                level: 'success',
                position: 'tc',
                autoDismiss: '5',
                dismissible: 'both',

            });
            this.setState({
                waitingResponse: false
            })
        }).catch(err => {
            this.setState({
                waitingResponse: false
            })
            var errMessage = "Problem me serverin, ju lutem te provoni perseri"
            if (err.response.data != "") {
                errMessage = err.response.data;
            }
            notification.addNotification({
                message: errMessage,
                level: 'warning',
                position: 'tc',
                autoDismiss: '5',
                dismissible: 'both',

            });
        })



    }

    showDeleteModal = () => {
        this.setState({
            deletionModal: {
                show: true
            }
        })
    }

    deleteAccount = (e) => {
        e.preventDefault();
        var notification = this.notificationSystem.current;
        this.userService.deleteAccount(this.state.user.userID).then(res => {
            notification.addNotification({
                message: i18n.t('Profili juaj eshte deaktivizuar me sukses!'),
                level: 'success',
                position: 'tc',
                autoDismiss: '5',
                dismissible: 'both',
            });
            setTimeout(this.logout, 5000)
        }).catch(err => {
            notification.addNotification({
                message: i18n.t('Ndodhi nje problem, te lutem provoni perseri.'),
                level: 'warning',
                position: 'tc',
                autoDismiss: '5',
                dismissible: 'both',
            });
        })
    }

    logout = () => {
        deleteToken();
        this.props.history.push('/user/dashboard')
    }
    render() {
        const user = this.state.user;
        const inputs = this.getInputs();
        const modalIsOpen = this.state.modal.show;
        const deleteModalIsOpen = this.state.deletionModal.show;
        const image = this.getImage(user)
        return (
            <div className="content background-tinted">
                <NotificationSystem ref={this.notificationSystem} />
                <Modal
                    className="modal-style flex align-center flex-space-around radius-4"
                    isOpen={modalIsOpen}
                    onRequestClose={this.handleClose}
                    contentLabel="Example Modal"
                >
                    <div className='confirmation-modal-div w-100 flex align-center flex-space-around'>
                        <h2 className='m-0'>{i18n.t('do you want to update')}</h2>
                        <div className='w-30 flex flex-space-between'>
                            <button className='form-control w-15 white bg-green no-border remove-btn-click-styles-green' onClick={this.save}>{i18n.t('Save')}</button>
                            <button className='form-control w-15 black' onClick={this.handleClose}>{i18n.t('Back')}</button>
                        </div>
                    </div>

                </Modal>

                <Modal
                    className="modal-style flex align-center flex-space-around radius-4"
                    isOpen={deleteModalIsOpen}
                    onRequestClose={this.handleClose}
                    contentLabel="Example Modal"
                >
                    <div className='confirmation-modal-div w-100 flex align-center flex-space-around'>
                        <h2 className='m-0'>{i18n.t('confirm deactivation')}</h2>
                        <div className='w-30 flex flex-space-between'>
                            <button className='form-control w-15 bg-red white no-border remove-btn-click-styles-red' onClick={e => this.deleteAccount(e)}>{i18n.t('Deactivate')}</button>
                            <button className='form-control w-15 black' onClick={this.handleClose}>{i18n.t('Back')}</button>
                        </div>
                    </div>

                </Modal>
                <h1 className="relative w-95 left-2">{i18n.t('your data')}</h1>
                <div className="w-100 h-100 flex flex-space-between user-profile-wrapper">
                    <Form onSubmit={(e) => this.handleSubmit(e)} className="w-60 bg-white radius-4 content-space-around height-70-vh flex wrap p-3 relative left-2 user-profile">
                        {inputs.map((inp, index) => {
                            return (
                                <FormGroup key={index} className={this.getClass(inp)}>
                                    {this.isLabelNeeded(inp.label.text) &&
                                        <CustomLabel data={inp.label} />}
                                    {inp.type === 'checkbox' ?
                                        <CustomInput
                                            agencyStateChanged={this.agencyStateChanged}
                                            handleFocus={this.doNothing}
                                            handleInput={this.handleInputChange}
                                            resetPassword={this.sendEmail}
                                            deleteAccount={this.showDeleteModal}
                                            data={inp}
                                            value={inp.inputName == "total-price" ? inp.value : undefined}
                                        /> : <FormControl name={inp.inputName} onChange={this.handleInputChange} onClick={(e) => this.handleClick(e, inp.action)} type={inp.type} className={inp.inputClass} value={inp.value} disabled={inp.disabled || false} />
                                    }
                                </FormGroup>
                            )
                        })}

                    </Form>
                    <div className="w-30 bg-white radius-4 height-70-vh flex vertical-flex flex-space-around wrap p-3 relative user-profile right-5 align-center">
                        <div className='profile-image-div'>
                            {image.split(',')[1] != "" ?
                                <img src={image} alt="" className='h-100 w-100 profile-pic' />
                                :
                                <h1 className='h-100 w-100 profile-pic flex text-center align-center'><i>{i18n.t('no photo')}</i></h1>
                            }
                        </div>
                        {image.split(',')[1] != "" &&
                            <Button onClick={this.removePhoto} className='bg-blue remove-photo-btn m-vertical-5-px'>{i18n.t('remove photo')}</Button>}
                        <input type="file" className='form-control image-input' onChange={this.handlePhotoChange} />
                        <h3>{i18n.t('Set a photo')}</h3>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(UserProfile);
