import React from "react";
import { BiXCircle } from 'react-icons/bi'

function SmallImage(props) {
    const shoudMakeXVisible = window.location.href.includes('/user/add/post') || window.location.href.includes('/update') ? true : false
    const image = props.Image.blobString;

    const deleteImage = () => {
        props.deleteImage(image);
    }
    return (
        <div key={props.imageKey} className="small-image-container relative">
            <img className={"small-image " + props.key}
                src={image} alt={props.Alternative} />
            {shoudMakeXVisible ?
                <BiXCircle onClick={() => deleteImage(image)} className='delete-image absolute' />
                : ""}
        </div>
    )
}

export default SmallImage;