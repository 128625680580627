import { Button } from "react-bootstrap";
import React, { Component } from "react";
import { FaShower } from "react-icons/fa";
import { MdEuroSymbol, MdOutlineStarPurple500 } from "react-icons/md";
import { RiStarSFill } from "react-icons/ri";
import { BiDollar } from 'react-icons/bi'
import { BiBed, BiX } from "react-icons/bi";
import { AiOutlineHistory } from "react-icons/ai";
// import SQMeter from '../CustomIcons/SquareMeter'
import { NavLink } from "react-router-dom";
import { GiStairsGoal, GiPerson, GiPathDistance, GiGearStickPattern, GiCarSeat, GiCarDoor } from "react-icons/gi";

import PostService from "../../services/PostService";

import Heart from "react-heart";
import i18n from "../../i18configuration/i18";
import SQMeter from "components/CustomIcons/SquareMeter";
import { PostTypes } from "helpers/postTypes";
//AiFillStar

class CardPost extends Component {
  constructor(props) {
    super(props);
    this.state = {
      x: 0,
      propertyType: "",
      post: this.props.post,
      postStyle: this.props.postStyle,
      hidden: this.props.hidden,
      showPrice: false,
      drag: false,
      mobile: false,
      postWidth: 0,
      sideMargin: 0,
      linkDisabled: false,
      sponsored: false,
      likes: 0,
      views: 0,
      liked: false
    };
  }
  postService = new PostService();
  specifyWidthIfMobile = () => {
    var deviceWidth = window.innerWidth;
    if (deviceWidth < 450) {
      var postWidth = deviceWidth / 80;
      var sideMargin = (deviceWidth - postWidth) / 2;
      this.setState({
        mobile: true,
        postWidth: postWidth,
        sideMargin: sideMargin,
      });
    }
  };

  getParam = (paramName) => {

    var search = this.props.location.search;
    alert(search);
    return new URLSearchParams(search).get(paramName);
  };
  likedClick = (event, postId) => {
    let localLiked = this.state.liked;
    localLiked = !localLiked;
    let like = this.state.likes;
    if (localLiked) {
      like = like + 1;
    } else {
      like = like - 1;
    }
    this.setState({ likes: like });
    this.postService.updateForLikes(postId, like)
    this.setState({ liked: localLiked });
  };

  componentDidMount() {
    if (
      window.location.href.includes("/user/post") ||
      window.location.href.includes("/user/post-list")
    ) {
      this.specifyWidthIfMobile();
    }
    if (this.props.showPrice) {
      this.setState({
        showPrice: true,
      });
    }
    if (this.props.sponsored) {
      this.setState({
        sponsored: true,
      });
    }
    this.createPostDescription();
  }

  createPostDescription = (post) => {
    var postTemp = post;
    if (!postTemp) return;
    var postDescription = [];
    postTemp = {
      ...postTemp,
      postDescription: postDescription,
    };
    if (postTemp) {
      if (
        postTemp.rooms &&
        (postTemp.postType && [1, 2, 3, 7].includes(postTemp.postType.postTypeId) ||
          window.location.href.includes("/add/post")))
        postTemp.rooms == 0
          ? (postTemp.postDescription = [
            ...postTemp.postDescription,
            {
              icon: "bedroom",
              info: i18n.t('Garsoniere'),
            },
          ])
          : (postTemp.postDescription = [
            ...postTemp.postDescription,
            {
              icon: "bedroom",
              info: `${postTemp.rooms} ${i18n.t(`dhom${postTemp.rooms == 1 ? `ë` : `a`}`)}`,
            },
          ]);

      if (postTemp.bathrooms && postTemp.bathrooms > 0)
        postTemp.postDescription = [
          ...postTemp.postDescription,
          {
            icon: "shower",
            info: postTemp.bathrooms + i18n.t('banjo'),
          },
        ];

      if (
        postTemp.space &&
        postTemp.space > 0 &&
        (postTemp.postType ||
          window.location.href.includes("/add/post"))
      )
        postTemp.postDescription = [
          ...postTemp.postDescription,
          {
            icon: "space",
            info: `${postTemp.space}${postTemp.postType &&
              postTemp.postType.postTypeId === PostTypes.Toke
              ? ` ${i18n.t('ari')} `
              : postTemp.unit
              }`,
          },
        ];
      if (
        ((postTemp.postType &&
          (postTemp.postType.postTypeId === PostTypes.Shtepi ||
            postTemp.postType.postTypeId === PostTypes.Ville)) ||
          window.location.href.includes("/add/post")) &&
        postTemp.kate &&
        postTemp.kate > 0
      )
        postTemp.postDescription = [
          ...postTemp.postDescription,
          {
            icon: "kate",
            info: `${postTemp.kate} ${i18n.t(`kat${postTemp.kate == 1 ? `` : `e`}`)}`,
          },
        ];
      if (
        (postTemp.postType && postTemp.postType.postTypeId === PostTypes.Ahengje) ||
        window.location.href.includes("/add/post")
      )
        postTemp.postDescription = [
          ...postTemp.postDescription,
          {
            icon: "people",
            info: `max ${postTemp.numberOfPeopleAllowed} ${i18n.t(`person${postTemp.numberOfPeopleAllowed == 1 ? `ë` : `a`}`)}`,
          },
        ];
      if (
        ((postTemp.postType &&
          postTemp.postType.postTypeId === PostTypes.Zyre) ||
          window.location.href.includes("/add/post")) &&
        postTemp.floor
      )
        postTemp.postDescription = [
          ...postTemp.postDescription,
          {
            icon: "floor",
            info: `${postTemp.floor === 0 ? `${i18n.t('Përdhesë')}` : ` ${postTemp.floor}`
              }`,
          },
        ];
      if (
        (postTemp?.postType?.postTypeId === PostTypes.Vetura ||
          window.location.href.includes("/add/post")) &&
        postTemp?.brand?.split(',').length === 1
        &&
        postTemp.manufactureYear > -1
      )
        postTemp.postDescription = [
          ...postTemp.postDescription,
          {
            icon: "year",
            info: `${`${i18n.t('Viti')} ${postTemp.manufactureYear}`}`,
          },
        ];
      if (
        (postTemp.postType?.postTypeId === PostTypes.Vetura && postTemp.rent &&
          window.location.href.includes("/add/post")) &&

        postTemp.nrSeats > -1
      )
        postTemp.postDescription = [
          ...postTemp.postDescription,
          {
            icon: "nrSeats",
            info: `${`${postTemp.nrSeats} ${i18n.t('ulëse')} `}`,
          },
        ];
      if (
        (postTemp?.postType?.postTypeId === PostTypes.Vetura ||
          window.location.href.includes("/add/post")) &&
        postTemp?.brand?.split(',').length === 1
        &&
        postTemp.kilometers > -1
      )
        postTemp.postDescription = [
          ...postTemp.postDescription,
          {
            icon: "kilometers",
            info: `${`${postTemp.kilometers} KM`}`,
          },
        ];
      if (
        (postTemp.postType?.postTypeId === PostTypes.Vetura ||
          window.location.href.includes("/add/post")) &&
        postTemp?.brand?.split(',').length === 1
        &&
        postTemp.transmision
      )
        postTemp.postDescription = [
          ...postTemp.postDescription,
          {
            icon: "transmision",
            info: `${`${postTemp.transmision}`}`,
          },
        ];
      if (
        (postTemp.postType?.postTypeId === PostTypes.Vetura && postTemp.rent &&
          window.location.href.includes("/add/post")) &&
        postTemp.nrDoors > -1
      )
        postTemp.postDescription = [
          ...postTemp.postDescription,
          {
            icon: "nrDoors",
            info: `${`${postTemp.nrDoors} ${i18n.t('dyer')}`}`,
          },
        ];
      return postTemp;
    }
  };

  componentDidUpdate() {
    if (this.state.post != this.props.post) {
      this.setState({
        post: this.props.post,
      });
      this.createPostDescription();
    }

    if (this.state.propertyType != this.props.propertyType) {
      this.setState({
        propertyType: this.props.propertyType,
      });
    }
    if (this.state.hidden != this.props.hidden) {
      this.setState({
        hidden: this.props.hidden,
      });
    }
  }
  getIcon = (icon) => {
    if (icon == undefined) return;
    else if (icon == "bedroom") {
      return <BiBed />;
    } else if (icon == "shower") {
      return <FaShower />;
    } else if (icon == "space") {
      return <SQMeter />;
    } else if (icon == "kate") {
      return <GiStairsGoal />;
    } else if (icon == "floor") {
      return <GiStairsGoal />;
    } else if (icon == "people") {
      return <GiPerson />;
    }
    //vetura
    else if (icon == "manufactureYear") {
      return <AiOutlineHistory />;
    } else if (icon == "kilometers") {
      return <GiPathDistance />;
    } else if (icon == "transmision") {
      return <GiGearStickPattern />;
    } else if (icon == "nrSeats") {
      return <GiCarSeat />;
    } else if (icon == "nrDoors") {
      return <GiCarDoor />;
    }
  };
  getIconClass = (className) => {
    var classN = "post-short-desc  h-4 m-5-perc w-90 relative left-5 grab ";
    if (className != undefined && className != "") classN += className;
    return classN;
  };

  getCurrency = (currency) => {
    if (currency === 'Euro') {
      return <MdEuroSymbol className="blue" />
    }
    else if (currency === 'USD') {
      return <BiDollar className="blue" />
    }
    else {
      return <MdEuroSymbol className="blue" />
    }
  }

  getPostClassName = () => {
    var mobile = this.state.mobile;
    var mobileClass = mobile ? "w-100-im " : "";
    var grabing = this.state.drag ? " grabbing" : " grab";
    var postClass =
      mobileClass +
      this.state.postStyle.postClass +
      grabing +
      " card-post-div-superior";
    if (this.state.hidden) {
      return (postClass += " not-shown");
    }
    return postClass;
  };
  handleMouseDown = (e) => {
    this.x = e.screenX;
    var nav = this.nav;
  };

  handleClick = (e) => {
    e.preventDefault();
    if (e.target.toString().includes("SVGPathElement")) return;
    const delta = Math.abs(e.screenX - this.x);

    if (delta < 10 && !window.location.href.includes("/add/post")) {
      if (this.props.onClick == undefined) return;
      this.props.onClick(this.state.post.postId);
    }
    e.preventDefault();
    this.x = 0;
    //}
  };

  disableLink = false;

  getPrimaryPhoto = (post) => {
    var primary = "";
    var prefix = "" + post.primaryPhotoExtension + "";

    if (
      post.primaryPhoto != null &&
      post.primaryPhoto.startsWith("data:image")
    ) {
      prefix = "";
    }
    if (post != undefined && post != null && post.primaryPhoto != "") {
      if (post.primaryPhotoExtension != undefined) {
        return post.primaryPhoto;
      }
      if (
        post.primaryPhotoExtension == undefined &&
        post.primaryPhoto != undefined &&
        post.primaryPhoto.startsWith("data:image")
      ) {
        return post.primaryPhoto;
      }
    }
    if (
      post != undefined &&
      post != null &&
      post.primaryPhoto == "" &&
      post.allPhotos != undefined &&
      post.allPhotos.length > 0
    ) {
      for (let i = 0; i < post.allPhotos.length; i++) {
        if (post.allPhotos[i].primary) {
          if (post.allPhotos[i].blobString.startsWith("data:image"))
            prefix = "";
          else
            prefix = "" + post.allPhotos[i].extension + "";
          primary = post.allPhotos[i].blobString;
          break;
        }
      }
      return primary;
    }

    if (
      post != undefined &&
      post != null &&
      post.photos != undefined &&
      post.photos.length > 0 &&
      post.photos[0].blobString != ""
    ) {
      for (let i = 0; i < post.photos.length; i++) {
        if (post.photos[i].primary) {
          if (
            post.photos[i].blobString != null &&
            post.photos[i].blobString.startsWith("data:image")
          )
            prefix = "";
          else prefix = "" + post.photos[i].extension + "";
          primary = post.photos[i].blobString;
          break;
        }
      }
      return primary;
    }
    if (primary == null) primary = "";
    return primary.trim();
  };
  currentIndex;

  getClassNameForDots = () => {
    var cls =
      "gray overflow-hidden text-overflow-ellipsis m-0 card-post-title-dots";
    if (window.location.href.includes("/user/post/"))
      cls += " card-dots-posts-page";
    return cls;
  };

  handleNavClick = (e) => {
    e.preventDefault();
    if (e.target.toString().includes("SVGPathElement")) return;
    const delta = Math.abs(e.screenX - this.x);
    if (delta > 10) {
      e.preventDefault();
    }
  };

  nav = React.createRef();
  render() {
    const post = this.createPostDescription(this.state.post);
    var query = window.location.search.substring(1);
    const dummyLikes = Math.floor(Math.random());
    const dummyViews = Math.floor(Math.random());
    if (!this.state.likes || this.state.likes <= 0) {
      this.state.likes = post.likes == undefined || post.likes < 0 ? dummyLikes : post.likes;
      this.state.views = post.views == undefined || post.views < 0 ? dummyViews : post.views;
    }
    const primaryImage = this.getPrimaryPhoto(post);
    const titleDots = this.getClassNameForDots();
    const descClassElement =
      post.postDescription != undefined &&
        post.postDescription.length > 0 &&
        post.kate != 0
        ? "flex font-15 icons-and-text"
        : "flex font-15 align-center";

    const mystyle = {
      color: "white",
      backgroundColor: "DodgerBlue",
      padding: "10px",
      fontFamily: "Arial"
    };
    return (
      <div
        key={post.postId}
        className={this.getPostClassName()}
        onClick={(e) => this.handleClick(e)}
        onMouseDown={(e) => this.handleMouseDown(e)}
      >
        <NavLink
          ref={this.nav}
          onClick={this.handleNavClick}
          to={
            !window.location.href.includes("/add/post")
              ? `/user/post-details/${post.postId}`
              : "#"
          }
        >
          <div className="flex vertical-flex card-post post-card-color inherit-all">
            {this.state.sponsored ? (
              <div className="star-sponsor">
                <RiStarSFill color="yellow" size={30} />
              </div>
            ) : (
              ""
            )}
            {this.state.sponsored ? <div className="sponsor-line"></div> : ""}
            <div className="card-post-image w-100 h-60 overflow-hidden clickable">
              {primaryImage != "" ? (
                <img
                  draggable={false}
                  src={primaryImage}
                  alt="card post image"
                  className="post-card-image w-100 fit-cover h-100"
                />

              ) : (
                <div className="w-100 h-100 background-tinted">
                  <h3 className="m-auto relative h-10 top-45 text-center">
                    <i>{i18n.t('chosen photo')}</i>
                  </h3>
                </div>
              )}
              {
                query.includes('input-ushqim=true') ||
                  query.includes('input-party=true') ||
                  query.includes('input-kafe=true') ||
                  query.includes('input-dasma=true') ?

                  <div>
                    <div style={{ position: "absolute", top: "8px", right: "16px", color: "white", backgroundColor: "black" }} className="top-right">  Klikimet  {this.state.views} </div>
                    <div style={{ position: "absolute", top: "30px", right: "16px", color: "white", backgroundColor: "black" }} className="top-right">Pelqimet {this.state.likes} </div>
                  </div>
                  : ""
              }

            </div>
            <div className=" relative w-90 left-5 h-20 ">
              <div className="flex flex-space-between align-center m-5-perc">

                <h3 className={titleDots}>
                  {post.title != undefined && post.title != "" ? (
                    post.title
                  ) : (
                    <i>{i18n.t('chosen title')}</i>
                  )}
                </h3>
                {this.state.showPrice ? (
                  <div className="flex align-center font-20  ">
                    <span>
                      {post.price && post.price > 0 ? (
                        post.price
                      ) : post.price <= 0 && post.dailyPricea <= 0 ? (
                        <i className="font-18"> {i18n.t('in place deal')}</i>) :
                        post.dailyPrice && post.dailyPrice > 0 ?
                          post.dailyPrice
                          :
                          post.postType?.postTypeId === PostTypes.Ahengje ?
                            <div style={{ width: "2rem" }}>
                              <Heart className="post-like" isActive={this.state.liked} onClick={(event) => this.likedClick(event, post.postId)} activeColor="red" inactiveColor="blue" animationTrigger="hover" animationScale={1.5} />
                            </div>
                            : (
                              <i>{i18n.t('price')}</i>
                            )}

                    </span>

                    {(post.price != undefined && post.price) > 0 || (post.dailyPrice != undefined && post.dailyPrice) > 0 ? (
                      this.getCurrency(post.currency)
                    ) : (
                      ""
                    )}
                    {(post.price && post.price > 0) || (post.dailyPrice && post.dailyPrice > 0) ? (
                      post.rent ? (
                        post.postType &&
                          [PostTypes.Ville, PostTypes.Vetura].includes(post.postType.postTypeId) ? (
                          <span className="blue">/{i18n.t('day')}</span>
                        ) : (
                          <span className="blue">/{i18n.t('month')}</span>
                        )
                      ) : post.cimere ? (
                        "/Person"
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>
              <p className="overflow-hidden text-overflow-ellipsis m-0 light-gray">
                {post["input-location"] != null && post["input-location"] != ""
                  ? post["input-location"]
                  : ""}
              </p>
            </div>
            <div
              className={this.getIconClass(
                this.props.postStyle.postDescriptionClass
              )}
            >
              {post.postDescription != undefined &&
                post.postDescription.length > 0
                ? post.postDescription.map((desc, index) => {
                  return (
                    <div key={index} className={descClassElement}>
                      <span className="blue">{this.getIcon(desc.icon)}</span>
                      <span className="ml-5-px flex align-center">
                        {i18n.t(desc.info)}
                      </span>
                    </div>
                  );
                })
                : ""}
            </div>
            <div className="line-divider h-2 "></div>
            <div className=" grab flex align-center vertical-flex">
              <div className="flex flex-space-around w-100 relative">
                <div className="relative w-15 left-5 h-100">
                  {post != undefined && post.rent != undefined && post.rent ? (
                    <Button className="bg-blue white p-0-15 m-2-perc no-border blue-text-hover">
                      {i18n.t('rent')}
                    </Button>
                  ) : (
                    ""
                  )}
                  {post.selling != undefined && post.selling ? (
                    <Button className="bg-blue white p-0-15 m-2-perc no-border blue-text-hover">
                      {i18n.t('buy')}
                    </Button>
                  ) : (
                    ""
                  )}
                  {post.cimere != undefined && post.cimere ? (
                    <Button className="bg-blue white p-0-15 m-2-perc no-border blue-text-hover">
                      {i18n.t('room mate')}
                    </Button>
                  ) : (
                    ""
                  )}
                  {post != undefined && post.party != undefined && post.party ? (
                    <Button className="bg-blue white p-0-15 m-2-perc no-border blue-text-hover">
                      {i18n.t('music')}
                    </Button>
                  ) : (
                    ""
                  )}
                  {post != undefined && post.ushqim != undefined && post.ushqim ? (
                    <Button className="bg-blue white p-0-15 m-2-perc no-border blue-text-hover">
                      {i18n.t('food')}
                    </Button>
                  ) : (
                    ""
                  )}
                  {post != undefined && post.kafe != undefined && post.kafe ? (
                    <Button className="bg-blue white p-0-15 m-2-perc no-border blue-text-hover">
                      {i18n.t('coffee')}
                    </Button>
                  ) : (
                    ""
                  )}
                  {post != undefined && post.dasma != undefined && post.dasma ? (
                    <Button className="bg-blue white p-0-15 m-2-perc no-border blue-text-hover">
                      {i18n.t('weddings')}
                    </Button>
                  ) : (
                    ""
                  )}


                </div>
                {post != undefined &&
                  post.postType != undefined &&
                  post.postType.postTypeId != undefined ? (
                  <div className="relative w-30 left-5 height-3-vh">
                    {post.postType.postTypeId != undefined &&
                      post.postType.postTypeId == PostTypes.Banese ? (
                      <Button className="bg-blue white p-0-15 m-2-perc no-border blue-text-hover">
                        {i18n.t('apartment')}
                      </Button>
                    ) : (
                      ""
                    )}
                    {post.postType.postTypeId != undefined &&
                      post.postType.postTypeId == PostTypes.Shtepi ? (
                      <Button className="bg-blue white p-0-15 m-2-perc no-border blue-text-hover">
                        {i18n.t('home')}
                      </Button>
                    ) : (
                      ""
                    )}
                    {post.postType.postTypeId != undefined &&
                      post.postType.postTypeId == PostTypes.Zyre ? (
                      <Button className="bg-blue white p-0-15 m-2-perc no-border blue-text-hover">
                        {i18n.t('office')}
                      </Button>
                    ) : (
                      ""
                    )}
                    {post.postType.postTypeId != undefined &&
                      post.postType.postTypeId == PostTypes.Toke ? (
                      <Button className="bg-blue white p-0-15 m-2-perc no-border blue-text-hover">
                        {i18n.t('land')}
                      </Button>
                    ) : (
                      ""
                    )}
                    {post.postType.postTypeId != undefined &&
                      post.postType.postTypeId == PostTypes.Lokal ? (
                      <Button className="bg-blue white p-0-15 m-2-perc no-border blue-text-hover">
                        {i18n.t('local')}
                      </Button>
                    ) : (
                      ""
                    )}

                    {post.postType.postTypeId != undefined &&
                      post.postType.postTypeId == PostTypes.Ville ? (
                      <Button className="bg-blue white p-0-15 m-2-perc no-border blue-text-hover">
                        {i18n.t('villa')}
                      </Button>
                    ) : (
                      ""
                    )}
                    {post.postType.postTypeId == PostTypes.Ahengje && (
                      <Button className="bg-blue white p-0-15 m-2-perc no-border blue-text-hover">
                        {i18n.t('fun')}
                      </Button>
                    )}
                    {post.postType.postTypeId == PostTypes.Vetura && (
                      <Button className="bg-blue white p-0-15 m-2-perc no-border blue-text-hover">
                        {i18n.t('cars')}
                      </Button>
                    )}
                  </div>
                ) : (
                  ""
                )}

                {this.state.propertyType != undefined &&
                  this.state.propertyType != "" ? (
                  <div className="relative w-30 left-5 height-3-vh">
                    <Button className="bg-blue white p-0-15 m-2-perc no-border blue-text-hover">
                      {this.state.propertyType.charAt(0).toUpperCase() +
                        this.state.propertyType.slice(1)}
                    </Button>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="flex align-center created-date">
                {post != undefined &&
                  post.timeToSortBy != undefined &&
                  post.timeToSortBy != "" ? (
                  <span>{`${i18n.t('posted at')} ${post.timeToSortBy.split("T")[0].split("-")[2]
                    }/${post.timeToSortBy.split("T")[0].split("-")[1]}/${post.timeToSortBy.split("T")[0].split("-")[0]
                    }`}</span>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </NavLink>
      </div>
    );
  }
}

export default CardPost;
