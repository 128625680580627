import React, { Component } from "react";
import { FaRegBuilding, FaCar } from "react-icons/fa";
import { AiOutlineCloudServer } from "react-icons/ai";
import i18n from "../i18configuration/i18";
import PostService from "../services/PostService.js";
import NotificationSystem from "react-notification-system";
import * as storage from "../services/storage";
import LoginService from "../services/LoginService";
import { setJWT, deleteToken } from "services/TokenService.js";
import VisitorService from "../services/Visitors.js";
import SearchBar from "components/SearchBar/SearchBar.jsx";
import { Button } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { BiRestaurant } from "react-icons/bi";
import CustomSlider from 'components/CustomSlider/CustomSlider'
import { checkLastTimeSynced, hasAdsLocally, supportStorage } from '../helpers/ads'
import { checkToken } from "../helpers/user";
const publicIp = require("public-ip");
/* eslint-disable no-return-assign, no-param-reassign */
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstAdloading: true,
      imageType: 'patundshmeri',
      secondAdloading: true,
      thirdAdloading: true,
      responsive: {
        deviceWidth: 0,
        hamburgerToggled: false,
      },
      playVideo: false,
      popular: [],
      firstAdsSlider: [],
      firstAdsSliderStartingIndex: 0,
      firstAdsSliderLastIndex: 2,
      secondAdsSlider: [],
    };
  }

  notificationSystem = React.createRef();
  createLegend(json) {
    var legend = [];
    for (var i = 0; i < json["names"].length; i++) {
      var type = "fa fa-circle text-" + json["types"][i];
      legend.push(<i className={type} key={i} />);
      legend.push(" ");
      legend.push(json["names"][i]);
    }
    return legend;
  }

  setResponsiveDesign = () => {
    var deviceWidth = window.innerWidth;

    this.setState({
      responsive: {
        ...this.state.responsive,
        deviceWidth: deviceWidth,
      },
    });
  };
  
  hasAdsLocally = (num) => {
    var localAdsArray = null;
    if (!supportStorage()) return false;
    if (!checkLastTimeSynced(num)) return false;
  
    if (num == 1) {
      let cacheData = storage.local.getItem("firstAdsSlider");
      if (cacheData && cacheData !== "undefined")
        localAdsArray = JSON.parse(storage.local.getItem("firstAdsSlider"));
      else return;
      if (localAdsArray) {
        this.setState({
          firstAdsSlider: localAdsArray,
        });
  
        return true;
      }
      return false;
    } else if (num == 2) {
      let cacheData = storage.local.getItem("secondAdsArray");
      if (cacheData && cacheData !== "undefined")
        localAdsArray = JSON.parse(storage.local.getItem("secondAdsArray"));
      else return;
      if (localAdsArray != undefined && localAdsArray != null) {
        this.setState({
          secondAdsSlider: localAdsArray,
        });
  
        return true;
      }
      return false;
    }
    return false;
  };

  visitorService = new VisitorService();
  loadAds = async () => {
    var today = new Date();
    var date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();
    var time =
      today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    var notification = this.notificationSystem.current;

    if (!this.hasAdsLocally(1)) {
      await publicIp
        .v4()
        .then((res) => {
          this.visitorService
            .sendVisitorIp(res)
            .then(() => { })
            .catch((err) => {
              notification.addNotification({
                message:
                  "Sistemi është në përditësim e sipër. Shumë shpejtë do të mund të kërkoni atë dëshironi!",
                level: "info",
                position: "tc",
                autoDismiss: "5",
                dismissible: "both",
              });
            });
        })
        .catch(() => { });

      await this.postService
        .getFirstAds()
        .then((res) => {
          this.setState({
            firstAdsSlider: res.data.data,
            firstAdloading: false,
          });
          if (supportStorage()) {
            var token = storage.local.getItem("tokenOntrine");
            var userid = storage.local.getItem("userOntrineID");
            storage.local.setItem("tokenOntrine", token);
            storage.local.setItem("userOntrineID", userid);
            storage.local.setItem("dateSync1", date);
            storage.local.setItem("timeSync1", time);
            storage.local.removeItem("firstAdsSlider");
            storage.local.setItem(
              "firstAdsSlider",
              JSON.stringify(res.data.data)
            );
          }
        })
        .catch((err) => {
          if (err.response != undefined)
            notification.addNotification({
              message: i18n.t("Problem me serverin (1), ju lutem beni refresh."),
              level: "warning",
              position: "tc",
              autoDismiss: "5",
              dismissible: "both",
            });
        });
    }
    if (!this.hasAdsLocally(2)) {
      await this.postService
        .getSecondAds()
        .then((res) => {
          this.setState({
            secondAdsSlider: res.data.data,
            secondAdloading: false,
          });
          if (supportStorage()) {
            var token = storage.local.getItem("tokenOntrine");
            var userid = storage.local.getItem("userOntrineID");
            storage.local.setItem("tokenOntrine", token);
            storage.local.setItem("userOntrineID", userid);
            storage.local.setItem("dateSync2", date);
            storage.local.setItem("timeSync2", time);
            storage.local.removeItem("secondAdsArray");
            storage.local.setItem(
              "secondAdsArray",
              JSON.stringify(res.data.data)
            );
          }
        })
        .catch((err) => {
          if (err.response != undefined)
            notification.addNotification({
              message: i18n.t("Problem me serverin, ju lutem beni refresh."),
              level: "warning",
              position: "tc",
              autoDismiss: "5",
              dismissible: "both",
            });
        });
    }
    await this.postService
      .getThirdAds()
      .then((res) => {
        this.setState({
          popular: res.data.data,
          thirdAdloading: false,
        });
      })
      .catch((err) => {
        if (err.response != undefined)
          notification.addNotification({
            message: i18n.t("Problem me serverin, ju lutem beni refresh."),
            level: "warning",
            position: "tc",
            autoDismiss: "5",
            dismissible: "both",
          });
      });
  };

  componentDidMount() {
    this.setResponsiveDesign();
    this.loadAds();
    checkToken();
  }

  getClassNameHiddenForSliderPost = (index) => {
    if (
      index >= this.state.firstAdsSliderStartingIndex &&
      index <= this.state.firstAdsSliderLastIndex
    )
      return false;
    return true;
  };

  postService = new PostService();
  loginService = new LoginService();

  fancyPostStyle = {
    postClass: "w-30 h-100 post relative radius-10 flex ",
    postDescriptionClass: "flex ",
  };

  nextPost = () => {
    if (
      this.state.firstAdsSliderLastIndex >=
      this.state.firstAdsSlider.length - 1
    )
      return;

    this.setState({
      firstAdsSliderStartingIndex: this.state.firstAdsSliderStartingIndex + 1,
      firstAdsSliderLastIndex: this.state.firstAdsSliderLastIndex + 1,
      // needsFiltering: true,
      firstAdSliderArrows: {
        ...this.state.firstAdSliderArrows,
        prev: true,
      },
    });

    if (
      this.state.firstAdsSliderLastIndex ==
      this.state.firstAdsSlider.length - 2
    ) {
      this.setState({
        firstAdSliderArrows: {
          prev: true,
          next: false,
        },
      });
    }
  };

  prevPost = () => {
    if (this.state.firstAdsSliderStartingIndex == 0) return;

    this.setState({
      firstAdsSliderStartingIndex: this.state.firstAdsSliderStartingIndex - 1,
      firstAdsSliderLastIndex: this.state.firstAdsSliderLastIndex - 1,
      // needsFiltering: true,
      firstAdSliderArrows: {
        ...this.state.firstAdSliderArrows,
        next: true,
      },
    });
    if (this.state.firstAdsSliderStartingIndex == 1) {
      this.setState({
        firstAdSliderArrows: {
          next: true,
          prev: false,
        },
      });
    }
  };

  sendToPostDetails = (postId) => {
    this.props.history.push({
      pathname: "/user/post-details/" + postId,
    });
  };

  sendToAboutUs = () => {
    this.props.history.push({
      pathname: "/user/about-us",
    });
  };
  toggleHamburger = () => {
    this.setState({
      responsive: {
        ...this.state.responsive,
        hamburgerToggled: !this.state.responsive.hamburgerToggled,
      },
    });
  };

  getPopularClassName = (index) => {
    var classToAdd = index == 0 ? "latest-container" : "popular-container";
    var classFinal =
      "flex-grow-1 w-50 clickable relative z-index-front " + classToAdd;
    return classFinal;
  };

  getPopularInnerDivClass = (index) => {
    var classToAdd =
      index == 0
        ? "colored-box blue-box bg-blue bottom-0 left-0 w-30 h-32 absolute z-index-back"
        : "colored-box gray-box bg-light-gray top-0 right-0 w-30 h-32 absolute z-index-back";
    return classToAdd;
  };

  getPrimaryPhoto = (post) => {
    var primary = "";
    var prefix = post.primaryPhotoExtension

    if (post != undefined && post != null && post.primaryPhoto != "") {
      return post.primaryPhoto;
    }
    if (
      post != undefined &&
      post != null &&
      post.photos != undefined &&
      post.photos.length > 0
    ) {
      primary = post.photos[0].blobString;
    }

    return primary;
  };

  getTextContainerClass = (index) => {
    var cls =
      "text-container post-description z-index-front absolute bottom-0 right-0 h-60 w-60 bg-white light-box-shadow p-2 flex vertical-flex ";
    var clsExtra = index == 0 ? " white-space" : "";
    return cls + clsExtra;
  };
  postData = {
    postClass: "w-100 h-100 post bg-white",
    postDescriptionClass: "flex flex-space-between",
  };



  changeBackgroundPhoto = (type) => {
    this.setState({
      imageType: type
    })
  }

  getPstDesc = (text, num) => {
    var splited = text.split(".");
    splited = splited.filter((text) => text != "");

    var leng = Math.floor(splited.length / 2);

    if (splited.length % 2 != 0) {
      leng += 1;
    }
    if (num == 1) {
      leng = splited.length - leng;
    }
    var line = "";
    var i = 0;
    if (num == 0) {
      while (i < leng) {
        line += splited[i] + ".";
        i++;
      }
    }
    if (num == 1) {
      i = splited.length - leng;
      while (i < splited.length) {
        line += splited[i] + ".";
        i++;
      }
    }

    return line;
  };

  resizedDone = (e) => {
    this.props.resizedDone();
  };
  render() {
    const responsive = this.state.responsive;
    const apartamentetClass =
      responsive.deviceWidth < 768
        ? "h-80 w-80 flex relative vertical-flex align-center left-10 top-10"
        : "h-80 w-80 flex relative left-10 top-10";

    const apartamentetTextClass =
      responsive.deviceWidth < 768
        ? "h-20 text-center w-100 flex vertical-flex"
        : "h-100 w-30 flex vertical-flex";
    const shadowClass = this.state.playVideo
      ? "absolute height-60-vh About-us-main-shadow w-100 shadow-thick"
      : "absolute height-60-vh About-us-main-shadow w-100";



    return (
      <div className="content">
        <NotificationSystem ref={this.notificationSystem} />

        <div className={shadowClass}></div>
        <div className={`About-us-main flex flex-center height-60-vh w-100 ${this.state.imageType}`}>
          <div className="About-us-content flex vertical-flex align-center white text-center absolute relative">
            <SearchBar
              changeBackgroundPhoto={this.changeBackgroundPhoto}
              resized={this.props.resized}
              resizedDone={this.resizedDone}
            />
            <div className="flex vertical-flex">
              <h1 className="m-2-perc left-text font-36">{i18n.t('find it')}</h1>

              <p className="m-2-perc left-text font-16">
                {i18n.t('all types')}
              </p>


              <Button
                onClick={this.sendToAboutUs}
                className="left-side transparent white w-30 about-us-button "
              >
                <NavLink to="/user/about-us">{i18n.t('about us')} </NavLink>
              </Button>
            </div>
          </div>
        </div>
        <div className="paid-ad-posts height-80-vh background-tinted">
          <div className="h-80 w-80 flex vertical-flex flex-space-between relative left-10 top-10">
            <div className="flex flex-space-between align-center h-100 slider-pages dashboard-slide">
              {this.state.firstAdsSlider != undefined &&
                this.state.firstAdsSlider.length > 0 ? (
                <CustomSlider posts={this.state.firstAdsSlider} />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div className="services flex vertical-flex text-center  height-50-vh flex-center">
          <h1 className="light-gray"> Ontrine.com </h1>
          <h2 className="light-gray">{i18n.t('quick solutions')}</h2>

          <div className="services-list flex relative w-75 left-12 flex-space-between flex-grow-1 mt-2-vh">
            <div className="service flex-basis-24-perc flex align-center light-box-shadow h-35">
              <FaRegBuilding className="blue w-20 font-30" />
              <div className="service-text flex-grow-1 text-left ml-5-px w-78">
                <p className="m-0">{i18n.t('real estate as you like')}</p>
                <span className="light-gray">
                  {i18n.t('your preferences')}
                </span>
              </div>
            </div>
            <div className="service flex-basis-24-perc flex align-center light-box-shadow h-35">
              <BiRestaurant className="blue w-20 font-30" />
              <div className="service-text flex-grow-1 text-left ml-5-px w-78">
                <p className="m-0">{i18n.t('best gastronomy')}</p>
                <span className="light-gray">
                  {i18n.t('gastronomy types')}
                </span>
              </div>
            </div>
            <div className="service flex-basis-24-perc flex align-center light-box-shadow h-35">
              <FaCar className="blue w-20 font-30" />
              <div className="service-text flex-grow-1 text-left ml-5-px w-78">
                <p className="m-0">{i18n.t('rent buy cars')}</p>
                <span className="light-gray">
                  {i18n.t('fast find')}
                </span>
              </div>
            </div>
            <div className="service flex-basis-24-perc flex align-center light-box-shadow h-35">
              <AiOutlineCloudServer className="blue w-20 font-30" />
              <div className="service-text flex-grow-1 text-left ml-5-px w-78">
                <p className="m-0">{i18n.t('find quick')}</p>
                <span className="light-gray">
                  {i18n.t('find within 5 minutes')}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="trending-apartments paid-ad-posts height-80-vh background-tinted">
          <div className={apartamentetClass}>
            <div className={apartamentetTextClass}>
              <h1 className="gray">{i18n.t('best apartments')}</h1>
              <NavLink className="font-18" to="#">
                {i18n.t('best offers')}
              </NavLink>
              <NavLink className="font-15" to="/user/post?page=1">
                {i18n.t('visit all')}
              </NavLink>
            </div>
            <div className="h-100 w-70 flex flex-space-between slider-wrap ">
              {/* {this.state.secondAdloading ? <ClipLoader color='blue' className="loader" /> : */}

              {this.state.secondAdsSlider != undefined &&
                this.state.secondAdsSlider.length > 0 ? (
                <CustomSlider posts={this.state.secondAdsSlider} fancy={true} />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div className="main-container  height-60-vh">
          <div className="grouper-container relative w-80 left-10 flex h-80 top-10">
            {/* {this.state.thirdAdloading ? <ClipLoader color='blue' className="loader" /> :  */}
            {/* {this.state.popular != undefined && this.state.popular.length > 0
              ? this.state.popular.map((post, index) => {
                  return (
                    <div className={this.getPopularClassName(index)}>
                      <div
                        className={this.getPopularInnerDivClass(index)}
                      ></div>
                      <div
                        id="firstPopular"
                        className="fit-cover-imp big-image absolute popular-div-images z-index-front popular-iamge-div "
                        onClick={() => this.sendToPostDetails(post.postId)}
                        style={{
                          backgroundImage:
                            "url('" + this.getPrimaryPhoto(post) + "')",
                        }}
                      ></div>

                      <div className={this.getTextContainerClass(index)}>
                        <h1 className="blue m-0 font-25">{post.title}</h1>

                        <div className="divider w-100 flex-grow-1 align-center flex">
                          <div className="bg-blue h-3-px w-10"></div>
                        </div>
                        <p className="light-gray m-0 three-dots">
                          {this.getPstDesc(post.description, 0)}
                        </p>
                        <div className="divider w-100 flex-grow-1 align-center flex">
                          <div className="bg-blue h-3-px w-10"></div>
                        </div>
                        <p className="text-overflow-ellipsis three-dots  light-gray m-0">
                          {this.getPstDesc(post.descriptionSecond, 0)}
                        </p>
                      </div>
                    </div>
                  );
                })
              : ""} */}
            <div className="flex-grow-1 w-50  relative z-index-front latest-container">
              <div className="colored-box blue-box bg-blue bottom-0 left-0 w-30 h-32 absolute z-index-back"></div>
              <div className="big-image absolute popular-div-images z-index-front popular-iamge-div"></div>

              <div className="text-container post-description z-index-front absolute bottom-0 right-0 h-60 w-60 bg-white light-box-shadow p-2 flex vertical-flex white-space">
                <h1 className="blue m-0 font-25">{i18n.t('ad space')}</h1>

                <div className="divider w-100 flex-grow-1 align-center flex">
                  <div className="bg-blue h-3-px w-10"></div>
                </div>
                <p className="light-gray m-0">
                  {i18n.t('building space ad')}
                </p>
                <div className="divider w-100 flex-grow-1 align-center flex">
                  <div className="bg-blue h-3-px w-10"></div>
                </div>
                <p className="text-overflow-ellipsis  light-gray m-0">
                  {i18n.t('contact us at')}
                </p>
              </div>
            </div>
            <div className="flex-grow-1 w-50  relative z-index-front popular-container">
              <div className="colored-box gray-box bg-light-gray top-0 right-0 w-30 h-32 absolute z-index-back"></div>
              <div className="big-image absolute popular-div-images z-index-front popular-iamge-div"></div>

              <div className="text-container post-description z-index-front absolute bottom-0 right-0 h-60 w-60 bg-white light-box-shadow p-2 flex vertical-flex">
                <h1 className="blue m-0 font-25">{i18n.t('ad space')}</h1>

                <div className="divider w-100 flex-grow-1 align-center flex">
                  <div className="bg-blue h-3-px w-10"></div>
                </div>
                <p className="light-gray m-0">
                  {i18n.t('building space ad')}
                </p>
                <div className="divider w-100 flex-grow-1 align-center flex">
                  <div className="bg-blue h-3-px w-10"></div>
                </div>
                <p className="text-overflow-ellipsis  light-gray m-0">
                  {i18n.t('contact us at')}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Dashboard;
/* eslint-enable no-return-assign, no-param-reassign */
