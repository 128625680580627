import React, { Component } from "react";
import { FaShower } from "react-icons/fa"
import { BiBed } from 'react-icons/bi'
import { NavLink } from "react-router-dom";
import SQMeter from "components/CustomIcons/SquareMeter";

class FancyCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            post: this.props.post,
            postStyle: this.props.postStyle,
            hidden: this.props.hidden,
            drag: false
        }
    }

    componentDidMount() {
        this.setState({
            post: this.props.post
        })
    }

    componentDidUpdate() {
        if (this.state.post != this.props.post) {
            this.setState({
                post: this.props.post
            })

        }
    }

    getPostClassName = () => {
        var grabing = this.state.drag ? " grabbing" : " grab";
        var postClass = this.state.postStyle.postClass + grabing + " overflow-hidden";
        if (this.state.hidden) {
            return postClass += " not-shown";
        }
        return postClass;
    }

    getPrimaryPhoto = (post) => {
        var primary = '';

        var prefix = post.primaryPhotoExtension
        if (window.location.pathname.match('add/post')) {

            prefix = "";
        }
        if (post != undefined && post != null && post.primaryPhoto != "") {
            return post.primaryPhoto;
        }
        if (post != undefined && post != null && post.photos != undefined && post.photos.length > 0 && post.photos[0].blobString != "") {
            primary = post.photos[0].blobString;

        }
        return primary;
    }

    handleMouseDown = e => {
        this.x = e.screenX;
        var nav = this.nav;
    }

    handleClick = e => {
        const delta = Math.abs(e.screenX - this.x);
        if (delta < 10) {
            if (this.props.onClick == null) return;
            this.props.onClick(this.state.post.postId);

        }
        e.preventDefault();
        this.x = 0;
    }

    handleNavClick = (e) => {
        const delta = Math.abs(e.screenX - this.x);
        if (delta > 10) {
            e.preventDefault()
        }
    }
    render() {
        const post = this.state.post;

        const primaryImage = this.getPrimaryPhoto(post);
        return (
            <div
                className={this.getPostClassName()}
                onClick={(e) => this.handleClick(e)}
                onMouseDown={(e) => this.handleMouseDown(e)}
            >
                <NavLink
                    to={`/user/post-details/${post.postId}`}

                    onClick={this.handleNavClick}>
                    {primaryImage != "" ?
                        <img draggable={false} className="h-100 fit-cover w-100" src={primaryImage} alt="" /> : ""}
                    <div className='absolute bottom-10 flex vertical-flex w-80 left-10'>
                        <h3 className='white text-overflow-ellipsis m-0 fancy-post-title-dots'>{post.title}</h3>
                        <div className="m-vertical-5-px flex light-gray fancy-desc">
                            <span>{post.rooms} <BiBed /></span>
                            <span>|</span>
                            <span>{post.bathrooms} <FaShower /></span>
                            <span>|</span>
                            <span>{post.space} {post.unit} <SQMeter /></span>
                        </div>
                        <span className='light-gray'>{post['input-location']}</span>
                    </div>
                </NavLink>
            </div>
        );
    }
}

export default FancyCard;