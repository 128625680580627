export const tokenExists = () => {
  var token = localStorage.getItem("tokenOntrine");
  if (token != null) return true;
  return false;
};

export const setJWT = (token) => {
  localStorage.setItem("tokenOntrine", token);
};

export const getJWT = () => {
  var token = localStorage.getItem("tokenOntrine");
  return token;
};

export const sendToLogin = () => {
  this.props.history.push("/login");
};

export const deleteToken = () => {
  localStorage.removeItem("tokenOntrine");
  localStorage.removeItem("userOntrineID");
};

export const getUserId = () => {
  return localStorage.getItem("userOntrineID");
};

export const setUserId = (id) => {
  localStorage.setItem("userOntrineID", id);
};
