import CardPost from "components/Card-Post/CardPost";
import FancyCard from "components/FancyCard/FancyCard";
import React, { Component, useState } from "react";
import { useHistory } from "react-router";
import withRouter from "react-router-dom/withRouter";
import { Swiper, SwiperSlide } from "swiper/react";

const AutoPlay = ({ posts, fancy, sponsored }) => {
  const history = useHistory();

  const postData = {
    postClass: "w-100 h-100 post bg-white",
    postDescriptionClass: "flex flex-space-between",
  };

  const fancyPostStyle = {
    postClass: "w-100 h-100 post relative radius-10 flex ",
    postDescriptionClass: "flex ",
  };

  const sendToPostDetails = (postid) => {
    history.push("/user/post-details/" + postid);
  };

  return (
    <div className="w-100 h-100">
      <Swiper
        direction="horizontal"
        grabCursor
        loop
        height={651}
        setWrapperSize
        spaceBetween={10}
        breakpoints={{
          1920: {
            slidesPerView: 3,
          },
          768: {
            slidesPerView: 2,
          },
          480: {
            slidesPerView: 1,
          },
        }}
        // infinite={true}
        // dots={true}
        // dotsClass="flex flex-center slick-dots"
        // autoplay={true}
        // arrows={false}
        // pauseOnDotsHover={true}
        // autoplaySpeed={3500}
        // speed={400}
        // swipe={true}
        // pauseOnHover={true}
        // swipeToSlide={true}
        // pauseOnFocus={true}
        // responsive={[
        //   {
        //     breakpoint: 1920,
        //     settings: {
        //       slidesToShow: 3,
        //     },
        //   },
        //   {
        //     breakpoint: 768,
        //     settings: {
        //       slidesToShow: 2,
        //     },
        //   },
        //   {
        //     breakpoint: 480,
        //     settings: {
        //       slidesToShow: 1,
        //     },
        //   },
        // ]}
        // slidesToShow={3}
      >
        {posts &&
          posts.length &&
          posts.map((ad, index) => {
            if (fancy)
              return (
                <SwiperSlide>
                  <FancyCard
                    onClick={sendToPostDetails}
                    key={index}
                    post={ad}
                    postStyle={fancyPostStyle}
                  />
                </SwiperSlide>
              );
            else
              return (
                <SwiperSlide>
                  <CardPost
                    onClick={sendToPostDetails}
                    key={index}
                    post={ad}
                    postStyle={postData}
                    sponsored={sponsored}
                  />
                </SwiperSlide>
              );
          })}
      </Swiper>
    </div>
  );
};

export default withRouter(AutoPlay);
